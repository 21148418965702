
































import {Component, Prop, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PersonService from "@/services/PersonService";
import InMemoryFileUpload from "@/components/common/InMemoryFileUpload.vue";
import FileService from "@/services/FileService";
import SaveIdCardRequest from "@/dto/person/SaveIdCardRequest";
import Workspace from "@/dto/auth/Workspace";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  components: {
    InMemoryFileUpload
  }
})
export default class PersonalIdCardModal extends Vue {
  private frontLabel = "Upload the front side";
  private backLabel = "Upload the back side";
  private successful = false;
  private message = "";
  private idCardFront!: File;
  private isIdCardFrontUpload = false;
  private isIdCardBackUpload = false;
  private idCardBack!: File

  @Prop()
  private onSaved!: () => void

  @AppModule.State
  private loading!: boolean;

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  handleFrontCard(file: File) {
    this.isIdCardFrontUpload = true;
    this.idCardFront = file;
  }

  handleBackCard(file: File) {
    this.isIdCardBackUpload = true;
    this.idCardBack = file;
  }

  get isIdCardUpload() : boolean {
    return this.isIdCardFrontUpload && this.isIdCardBackUpload
  }

  errorHandle(error: any) {
    this.successful = false;
    this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
    this.stopLoading();
  }

  save() {
    this.message = "";
    this.startLoading()
    let request = new SaveIdCardRequest();

    Promise.all([FileService.uploadFile(this.idCardFront, Workspaces.getCurrent.userId, this.idCardFront.name),
      FileService.uploadFile(this.idCardBack, Workspaces.getCurrent.userId, this.idCardFront.name)]).then(
        ([front, back]) => {
          request.idCardFront = front.data.id;
          request.idCardBack = back.data.id;
        }
    ).then(
        () => {
          request.personId = Workspaces.getCurrent.id;
          PersonService.uploadPersonalIdCard(request).then(
              success => {

                this.successful = true;
                this.stopLoading();
                this.$modal.hideAll();
                this.onSaved();
              }
          )
        }
    ).catch(err => this.errorHandle(err))

  }
}

