

































import {Component, Prop, Vue, Emit} from 'vue-property-decorator';
import {documentAccept} from "@/constants/FileConstants";
import {namespace} from "vuex-class";

const AppModule = namespace("App");

@Component
export default class InMemoryFileUpload extends Vue {

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private fileName = "";

	private isFileUpload = false;

	@Prop({default: true})
	private enabled!: boolean;

	@Prop()
	private userId!: number;

	private height = "200px";

	@Prop({default: () => ""})
	private generateCustomName!: (() => string) | null;

	private hightlight = false;

	@Prop({default: documentAccept})
	private accept!: string;

	@Prop({default: "Upload file"})
	private customLabel!: string;

	@Emit("filesUploaded")
	emitFiles(file: File){
		this.height = "50px"
		this.fileName = file.name;
		this.isFileUpload = true;
		return file;
	}

	openFileSelection(): void {
		if (!this.enabled) return;
		(this.$refs.fileInput as HTMLElement).click();
	}

	onDragOver(event: DragEvent): void {
		if (!this.enabled) return;
		event.preventDefault();
		this.hightlight = true;
	}

	onDragLeave(event: DragEvent): void {
		this.hightlight = false;
	}

	onDrop(event: DragEvent): void {
		if (!this.enabled || !event.dataTransfer) return;
		event.preventDefault();
		const files = event.dataTransfer.files;
		let result = files.item(0) as File;
		this.hightlight = false;
		this.emitFiles(result);
	}

	onFileChanged(event: any) {
		let result: File = event.target.files.item(0) as File;
		this.emitFiles(result);
	}
}
